import React, { FC, memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { ComponentType, LayoutComponentProps, LayoutProps } from '@sport1/types/web'
import dynamic from 'next/dynamic'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import renderBaseChannelComponent from '@/helpers/renderBaseChannelComponent'
import { useNavigation } from '@/utils/navigation/Standard'
import { BreakPointProps } from '@/helpers/breakpoint'
import { useRedundantTeaserFunctions } from '@/utils/content/RedundantTeaserProvider'
import PageContentLayoutFallback from '@/layouts/Standard/PageContentLayout/Fallback'
import { isPureActive } from '@/components/ThirdParty/Piano/util'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

type Props = {
    layoutData: LayoutProps
    isAdsActive?: boolean // to disable ads, need to pass 'false', otherwise ads enabled
}

const TopVideo = dynamic(() => import('@/components/TopVideo'))
const ArticleSection = dynamic(() => import('@/components/Article/Section/Standard'))

const IGNORED_WHEN_ADS_DISABLED: string[] = [ComponentType.AD]
export const NOT_COUNTING_PAGE_COMPONENTS = [ComponentType.AD, ComponentType.HEADLINE]

export const PageContentLayout: FC<Props> = ({ layoutData, isAdsActive = true }) => {
    isAdsActive = isAdsActive && !isPureActive()
    const { navigation } = useNavigation()
    const { asPath } = useRouter()
    const dashboardPosition = useRef(1)
    const { enrichAdsWithId } = useAdPlacementContext()
    React.useLayoutEffect(() => {
        enrichAdsWithId(layoutData.components as LayoutComponentProps[])
    }, [layoutData.components, enrichAdsWithId])
    const { removeTeaserIds } = useRedundantTeaserFunctions()
    const newLayout = removeTeaserIds(layoutData, true, navigation?.tag?.contextId)
    const hasContent = useRef(false)
    const theme = useTheme()

    useEffect(() => {
        // Reset the dashboardPosition after layout changes (e.g. paging)
        if (layoutData) {
            dashboardPosition.current = 1
        }
    }, [layoutData])

    const renderChannelComponent = useCallback(
        ({
            component,
            rightColumnComponents,
        }: {
            component: LayoutComponentProps
            rightColumnComponents: LayoutComponentProps[]
        }): ReactElement | null => {
            const key =
                component.type && component.componentKey
                    ? `render-channel-${component.type}_${component.componentKey}`
                    : undefined

            if (component.type === ComponentType.TOP_VIDEO) {
                return (
                    <div data-testid="topvideo" key={key}>
                        <TopVideo topVideoData={component} />
                    </div>
                )
            }

            if (component.type === ComponentType.ARTICLE_SECTION) {
                return (
                    <ArticleSection
                        content={component.content}
                        followLinks={!!layoutData.meta?.followContentLinks}
                        isAdsActive={isAdsActive}
                        isSponsored={component.isSponsored}
                        key={key}
                    />
                )
            }

            if (
                (isAdsActive || !IGNORED_WHEN_ADS_DISABLED.includes(component.type)) &&
                typeof component.targetFragment === 'undefined' // ignore rightColumn elements
            ) {
                return renderBaseChannelComponent({
                    component,
                    pageTag: navigation?.tag,
                    rightColumnComponents,
                    dashboardPosition,
                    theme: theme as BreakPointProps,
                    pathName: asPath,
                })
            }

            return null
        },
        [isAdsActive, layoutData.meta?.followContentLinks, navigation, theme, asPath]
    )

    const pageComponents = useMemo(
        () =>
            newLayout.components.flatMap((component: LayoutComponentProps) => {
                const pageComponent = renderChannelComponent({
                    component,
                    rightColumnComponents: newLayout.components.filter(
                        c =>
                            typeof c.targetFragment !== 'undefined' &&
                            c.targetFragment === component.fragmentIndex
                    ),
                })

                // On some pages (e.g. team highlights) we get the Headline as component.
                // If response has no Content (excluding AD and HEADLINE), we render fallback
                if (!NOT_COUNTING_PAGE_COMPONENTS.includes(component.type)) {
                    hasContent.current = true
                }

                return pageComponent ? [pageComponent] : []
            }),
        [newLayout.components, renderChannelComponent]
    )

    return hasContent.current ? (
        <>{pageComponents}</>
    ) : (
        <PageContentLayoutFallback pageComponents={pageComponents} />
    )
}

export default memo(PageContentLayout)

import { useCallback, useRef, useState } from 'react'
import { LabelProps, LayoutProps, TagProps } from '@sport1/types/web'
import { getPaginatedLayout } from '@/pages/api/cms/content/tag/[id]'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

type LayoutPaginationProps = {
    initialLayout: LayoutProps
    initialPage?: number
    url?: string
    tag?: TagProps | LabelProps
}
type Hook = {
    layout: LayoutProps
    hasNextPage: boolean
    loadNext: () => void
}

const useLayoutPagination = ({
    initialLayout,
    initialPage = 0,
    url,
    tag,
}: LayoutPaginationProps): Hook => {
    const page = useRef(initialPage)
    const [hasNextPage, setHasNextPage] = useState(!!url)
    const [layout, setLayout] = useState(initialLayout)
    const isFetchingNextPage = useRef(false)
    const { enrichAdsWithId } = useAdPlacementContext()

    const loadNext = useCallback(async () => {
        if (url && !isFetchingNextPage.current) {
            isFetchingNextPage.current = true
            page.current += 1
            getPaginatedLayout(url, tag, page.current)
                .then(nextLayout => {
                    if (nextLayout.components.length > 0) {
                        enrichAdsWithId(nextLayout.components)
                        setLayout(prevLayout => ({
                            ...prevLayout,
                            components: [...prevLayout.components, ...nextLayout.components],
                        }))
                    } else {
                        setHasNextPage(false)
                    }
                    isFetchingNextPage.current = false
                })
                .catch(() => {
                    setHasNextPage(false)
                    isFetchingNextPage.current = false
                })
        }
    }, [enrichAdsWithId, tag, url])

    return { layout, hasNextPage, loadNext }
}

export default useLayoutPagination

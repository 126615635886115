import { useCallback, useEffect, useRef, useState } from 'react'
import { LabelProps, LayoutProps, TagProps } from '@sport1/types/web'
import { getLayoutByTag } from '@/pages/api/cms/content/tag/[id]'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

type ReturnValue = {
    pagedLayout: LayoutProps
}

type AutoPagingParams = {
    tag?: TagProps | LabelProps
    isNews?: boolean
    layoutData?: LayoutProps
}

const useAutoPaging = ({ tag, isNews = false, layoutData }: AutoPagingParams): ReturnValue => {
    const currentPage = useRef(0)
    const isFetchingNextPage = useRef(false)
    const hasNextPage = useRef(true)
    const [currentLayoutData, setCurrentLayoutData] = useState(layoutData || { components: [] })
    const { enrichAdsWithId } = useAdPlacementContext()

    const loadNextPage = useCallback(() => {
        if (!isFetchingNextPage.current) {
            isFetchingNextPage.current = true
            currentPage.current = currentPage.current + 1
            if (tag) {
                getLayoutByTag(tag, currentPage.current)
                    .then(layout => {
                        if (layout && layout.components.length > 0) {
                            enrichAdsWithId(layout.components)

                            setCurrentLayoutData({
                                ...currentLayoutData,
                                components: [...currentLayoutData.components, ...layout.components],
                            })
                        } else {
                            hasNextPage.current = false
                            window.removeEventListener('scroll', loadNextPage)
                        }
                    })
                    .catch(() => {
                        hasNextPage.current = false
                        window.removeEventListener('scroll', loadNextPage)
                    })
            }
        }
    }, [currentLayoutData, enrichAdsWithId, tag])

    useEffect(() => {
        isFetchingNextPage.current = false
        if (hasNextPage.current && tag && layoutData && !isNews) {
            window.addEventListener('scroll', loadNextPage)
        }
        return () => window.removeEventListener('scroll', loadNextPage)
    }, [loadNextPage, layoutData, tag, isNews])

    return { pagedLayout: currentLayoutData }
}

export default useAutoPaging

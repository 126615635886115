import React, { FC } from 'react'
import { GetServerSideProps } from 'next'
import { AdPlacementType } from '@sport1/types/web'
import Button from '@sport1/news-styleguide/Button'
import Grid from '@sport1/react-elements/Grid'
import { spacings } from '@sport1/news-styleguide/spacings'
import { getTagBySlug } from '../api/cms/content/tag/[id]'
import StandardLayout from '@/layouts/Standard'
import { getSport1Navigation } from '@/pages/api/cms/navigation'
import { getLayoutByTag } from '@/pages/api/cms/content/tag/[id]'
import PageContentLayout from '@/layouts/Standard/PageContentLayout'
import useLayoutPagination from '@/hooks/useLayoutPagination'
import { PageProps } from '@/types/page'
import handleRejection from '@/utils/errors/handleRejection'
import { addAdsToLayout, AdPlatform } from '@/helpers/adHelper'
import extendNews from '@/utils/seo/extendNews'
type NewsPageProps = PageProps & {
    parentUrl?: string
}

const Index: FC<NewsPageProps> = ({ navigation, layoutData, parentUrl }) => {
    const { layout, hasNextPage, loadNext } = useLayoutPagination({
        initialLayout: layoutData,
        initialPage: 0,
        url: parentUrl ?? navigation.tag?.navigationItems?.[0].url,
        tag: navigation.tag,
    })

    return (
        <StandardLayout navigation={navigation}>
            <PageContentLayout layoutData={layout} />
            {hasNextPage && (
                <Grid centerHorizontal marginTop={spacings['spacing-6']} testID="load-more-button">
                    <Button
                        text="Mehr News laden"
                        onPress={loadNext}
                        paddingY="spacing-5"
                        paddingX="spacing-9"
                    />
                </Grid>
            )}
        </StandardLayout>
    )
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ctx => {
    let navigation, tag, layoutData
    try {
        ;[navigation, tag] = await Promise.all([getSport1Navigation(), getTagBySlug('/news')])

        layoutData = await getLayoutByTag(tag)
    } catch (error) {
        return handleRejection(error)
    }

    const deeplink = `sport1://tag/${tag.contextId}`
    ctx.res.setHeader('deeplink', deeplink)
    addAdsToLayout({
        adInfos: [
            {
                placement: AdPlacementType.INPAGE,
                platform: AdPlatform.WEB,
            },
        ],
        layout: layoutData,
    })
    extendNews({ layoutData })

    return {
        props: {
            layoutData,
            navigation: {
                ...navigation,
                tag,
            },
            deeplink,
        },
    }
}

export default Index
